<template>
  <div>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card elevation="0">
        <v-card-title class="headline"> Excluir Registro </v-card-title>
        <v-card-text
          >Confirma a Exclusão Permanentemente do Registro? Após Excluir, NÃO
          Será Possível REVERTER</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="deleteDialog = false">
            CANCELAR
          </v-btn>
          <v-btn color="red" text @click="deleteItem"> EXCLUIR </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <v-card elevation="0">
        <v-toolbar elevation="0">
          <v-row class="d-flex justify-space-around">
            <v-col cols="4">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
                height="40"
              ></v-text-field>
            </v-col>
            <v-spacer />
            <v-col cols="2">
              <v-btn color="primary" @click="openDialog = true">Novo</v-btn>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="suppliers"
          :search="search"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon small class="mr-2" @click="deleteOpen(item.id)"
              >mdi-eraser</v-icon
            >
          </template>
        </v-data-table>
      </v-card>
    </v-container>

    <v-row justify="center">
      <v-dialog
        v-model="openDialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card elevation="0">
          <v-toolbar>
            <v-btn icon @click="closeDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Cadastro de Fornecedor</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-container fluid>
              <v-card outlined>
                <v-card-subtitle bold>Dados Gerais</v-card-subtitle>
                <v-divider />
                <v-container fluid>
                  <v-row>
                    <v-col cols="3">
                      <v-select
                        outlined
                        :items="['Jurídica', 'Fisica']"
                        v-model="juridicalType"
                        label="Tipo Pessoa"
                      ></v-select>
                    </v-col>

                    <v-col cols="5">
                      <v-text-field
                        outlined
                        :label="
                          juridicalType === 'Fisica' ? 'Nome' : 'Razão Social'
                        "
                        v-model="socialName"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        :label="
                          juridicalType === 'Fisica'
                            ? 'Apelido'
                            : 'Nome Fantasia'
                        "
                        v-model="fantasyName"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        :label="juridicalType === 'Fisica' ? 'CPF' : 'CNPJ'"
                        v-model="idDocument"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        :label="juridicalType === 'Fisica' ? 'RG' : 'I.E'"
                        v-model="stateDocument"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="4">
                      <v-text-field
                        outlined
                        type="date"
                        :label="
                          juridicalType === 'Fisica'
                            ? 'Data Nascimento'
                            : 'Data Fundação'
                        "
                        v-model="fundationDate"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
              <v-row justify="center">
                <v-container fluid>
                  <v-expansion-panels accordion>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        >Endereço</v-expansion-panel-header
                      >
                      <v-divider />
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="3">
                            <v-text-field
                              label="CEP"
                              v-model="zipCode"
                              required
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Logradouro"
                              v-model="address"
                              required
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-text-field
                              label="Número"
                              v-model="number"
                              required
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="3">
                            <v-text-field
                              label="Bairro"
                              v-model="zone"
                              required
                              outlined
                            ></v-text-field>
                          </v-col>

                          <v-col cols="5">
                            <v-text-field
                              label="Complemento"
                              v-model="complement"
                              required
                              outlined
                            ></v-text-field>
                          </v-col>

                          <v-col cols="5">
                            <v-text-field
                              label="Cidade"
                              v-model="city"
                              required
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="2">
                            <v-text-field
                              label="Estado"
                              v-model="state"
                              required
                              outlined
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-container>
              </v-row>

              <v-row justify="center">
                <v-container fluid>
                  <v-expansion-panels accordion>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        >Contatos</v-expansion-panel-header
                      >
                      <v-divider />
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col cols="4">
                            <v-text-field
                              label="Fone"
                              v-model="phone"
                              required
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Celular"
                              v-model="whatsapp"
                              required
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field
                              label="Email"
                              v-model="email"
                              required
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-textarea
                              label="Observações"
                              v-model="observations"
                              required
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-container>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDialog()">
                  Fechar
                </v-btn>
                <v-btn
                  :loading="loadingAction"
                  color="success"
                  @click="registerOrUpdate()"
                >
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
export default Vue.extend({
  name: "Supplier",
  data: () => ({
    deleteDialog: false,
    suppliers: [],
    search: "",
    loading: false,
    loadingAction: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    id: "",
    juridicalType: "",
    socialName: "",
    fantasyName: "",
    fundationDate: "",
    address: "",
    zone: "",
    complement: "",
    number: "",
    city: "",
    state: "",
    zipCode: "",
    zone: "",
    phone: "",
    state: "",
    idDocument: "",
    stateDocument: "",
    whatsapp: "",
    email: "",
    observations: "",
    openDialog: false,
    headers: [
      {
        text: "Razão Social/Nome",
        align: "start",
        value: "socialName",
        filtering: true,
      },
      {
        text: "Nome Fantasia/Apelido",
        align: "start",
        value: "fantasyName",
        filtering: true,
      },
      {
        text: "Tipo",
        align: "start",
        value: "juridicalType",
        filtering: true,
      },
      { text: "Ações", value: "actions" },
    ],
  }),
  methods: {
    deleteOpen(itemId) {
      this.id = itemId;
      this.deleteDialog = true;
    },
    deleteItem() {
      http.delete(`suppliers/${this.id}`).then(
        () => {
          this.snackbar.color = "green";
          this.snackbar.text = "Registro Excluido com Sucesso!";
          this.snackbar.opened = true;
          this.deleteDialog = false;
          this.getItems();
        },
        () => {
          this.snackbar.color = "red";
          this.snackbar.text = "Erro ao Excluir Registro. Tente Novamente!";
          this.snackbar.opened = true;
        }
      );
    },
    getItems() {
      this.loading = true;
      http.get("suppliers").then((data) => {
        this.suppliers = data.data;
        this.loading = false;
      });
    },
    registerOrUpdate() {
      this.loadingAction = true;
      const payload = {
        socialName: this.socialName,
        fantasyName: this.fantasyName,
        idDocument: this.idDocument,
        juridicalType: this.juridicalType,
        phone: this.phone,
        whatsapp: this.whatsapp,
        email: this.email,
        address: this.address,
        zone: this.zone,
        zipCode: this.zipCode,
        city: this.city,
        state: this.state,
        fundationDate: this.fundationDate,
        complement: this.complement,
        number: this.number,
        stateDocument: this.stateDocument,
        observations: this.observations,
        country: "BR",
        congregationId: localStorage.getItem("congregationId"),
      };
      if (this.id) {
        http.put(`suppliers/${this.id}`, payload).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Atualizado com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.closeDialog();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Atualizar. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      } else {
        http.post("suppliers", payload).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Inserido com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.closeDialog();
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Registro. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      }
    },
    closeDialog() {
      this.openDialog = false;
      this.socialName = "";
      this.fantasyName = "";
      this.idDocument = "";
      this.stateDocument = "";
      this.juridicalType = "";
      this.address = "";
      this.zone = "";
      this.city = "";
      this.zipCode = "";
      this.fundationDate = "";
      this.state = "";
      this.observations = "";
      this.id = "";
      this.phone = "";
      this.email = "";
      this.whatsapp = "";
      this.complement = "";
      this.number = "";
      this.getItems();
    },
    editItem(item) {
      this.socialName = item.socialName;
      this.fantasyName = item.fantasyName;
      this.idDocument = item.idDocument;
      this.stateDocument = item.stateDocument;
      this.juridicalType = item.juridicalType;
      this.address = item.address;
      this.zone = item.zone;
      this.city = item.city;
      this.state = item.state;
      this.observations = item.observations;
      this.id = item.id;
      this.phone = item.phone;
      this.email = item.email;
      this.whatsapp = item.whatsapp;
      this.zipCode = item.zipCode;
      this.fundationDate = item.fundationDate;
      this.complement = item.complement;
      this.number = item.number;
      this.openDialog = true;
    },
  },
  mounted() {
    this.getItems();
  },
});
</script>